<i18n src="@i18n/service/service.th.yaml" locale="th" lang="yaml"/>
<i18n locale="th" lang="yaml" >
page.title : "ใบซ่อม"
page.title_onfield : "ใบซ่อมนอกสถานที่"
page.description : "แสดงและค้นหา ใบซ่อม ที่อยู่ในระบบ"

service.search.keyword : "หมายเลขใบซ่อม"
service.search.drone.name : "Drone"
service.search.keyword.placeholder: "ค้นหา"
service.search.drone.placeholder: "ค้นหาจากชื่อ Drone หรือ S/N"

service.field.status.placeholder : "สถานะใบซ่อม"
service.field.company.placeholder : "เลือกศูนย์ที่เปิดใบซ่อม"

service.company.chart.name: "ใบซ่อมในแต่ละศูนย์บริการ"
service.opener.chart.name: "ใบซ่อมรับแจ้งโดย"
service.status.chart.name: "สถานะใบซ่อม"
service.drone.chart.name: "Drone"
</i18n>
<template>
	<div class="page page-padding">
		<my-page-header v-if="showOnfieldOnly" :title="$t('page.title_onfield')" :description="$t('page.description')" />
		<my-page-header v-else :title="$t('page.title')" :description="$t('page.description')" />

		<div v-if="!showOnfieldOnly" class="myform-action-pane">
			<a-form layout="inline">
				<a-form-item :label="$t('service.search.keyword')">
					<a-input v-model="filter.keyword" style="width: 250px" :placeholder="$t('service.search.keyword.placeholder')" allow-clear>
						<a-icon slot="suffix" type="search" />
					</a-input>
				</a-form-item>

				<a-form-item :label="$t('service.search.drone.name')">
					<a-input v-model="filter.droneName" style="width: 250px" :placeholder="$t('service.search.drone.placeholder')" allow-clear>
						<a-icon slot="suffix" type="search" />
					</a-input>
				</a-form-item>

				<a-form-item :label="$t('service.field.status')">
					<a-select
						v-model="filter.statusList"
						mode="multiple"
						class="service-status-select"
						:get-popup-container="getPopupContainer"
						:placeholder="$t('service.field.status.placeholder')"
						:options="statusOptions"
						allow-clear
						@change="statusSelected" />
				</a-form-item>

				<a-form-item class="myform-expandable-row">
					<ExpanableLink v-model="searchMore" class="search-more-link ant-form-text" :title="$t('common.search.more_options')" />
				</a-form-item>

				<CollapseTransition>
					<div v-show="searchMore" class="myform-expandable-pane">
						<a-form-item :label="$t('service.field.event.date')">
							<MyRangeDatePicker v-model="filter.openDates" />
						</a-form-item>
						<a-form-item :label="$t('service.field.company')">
							<CompanySelect
								v-model="filter.reportByCompanyId"
								:placeholder="$t('service.field.company.placeholder')"
								allow-clear
								@change="companySelected" />
						</a-form-item>
					</div>
				</CollapseTransition>
			</a-form>
		</div>
		<div v-if="!showOnfieldOnly" class="myform-action-pane active-services-chart-panel">
			<div class="active-services-chart">
				<ServicesChart
					v-if="companyChartData && !showOnfieldOnly"
					ref="companyChart"
					:container-width="600"
					:chart-data="companyChartData"
					:total-data="totalCompanyChartData"
					:name="$t('service.company.chart.name')"
					@clicked="onClickCompany" />
			</div>
			<div class="active-services-chart">
				<ServicesChart
					v-if="openerChartData && !showOnfieldOnly"
					:container-width="600"
					:chart-data="openerChartData"
					:total-data="totalOpenerChartData"
					:name="$t('service.opener.chart.name')"
					@clicked="onClickOpener" />
			</div>
			<div class="active-services-chart">
				<ServicesChart
					v-if="statusChartData && !showOnfieldOnly"
					ref="statusChart"
					:container-width="600"
					:chart-data="statusChartData"
					:total-data="totalStatusChartData"
					:is-enum-required="true"
					:name="$t('service.status.chart.name')"
					@clicked="onClickStatus" />
			</div>
			<div class="active-services-chart">
				<ServicesChart
					v-if="droneChartData && !showOnfieldOnly"
					:container-width="600"
					:chart-data="droneChartData"
					:total-data="totalDroneChartData"
					:name="$t('service.drone.chart.name')"
					@clicked="onClickDrone" />
			</div>
		</div>
		<a-card :bordered="false">
			<ServiceTableAllComPanies
				bordered
				:show-onfield-only="showOnfieldOnly"
				:row-key="(record) => record.id"
				:data-source="serviceDataList"
				:pagination="pagination"
				:loading="loading"
				@change="handleTableChange" />
		</a-card>
	</div>
</template>
<script>
import PageMixin from '@mixins/PageMixin.vue'
import LoginInfoMixin from '@mixins/LoginInfoMixin.vue'
import CompanySelect from '@components/company/CompanySelect.vue'
import ExpanableLink from '@components/common/ExpandableLink.vue'
import MyRangeDatePicker from '@components/input/MyRangeDatePicker.vue'
import ServiceTableAllComPanies from '@components/service/ServiceTableAllCompanies.vue'
import ApiError from '@utils/errors/ApiError'
import { CollapseTransition } from 'vue2-transitions'
import axios from 'axios'
import _debounce from 'lodash/debounce'
import PopupMixin from '@mixins/PopupMixin.vue'
import { getEnumSelectOptions } from '@utils/formUtil'
import ServicesChart from '@components/chart/ServicesChart.vue'
import { mapGetters, mapState } from 'vuex'
import {SERVICE_STATUS} from '@utils/serviceUtil'
export default {
	page() {
		return {
			title: this.$t('page.title'),
		}
	},
	components: {
		ServiceTableAllComPanies,
		CompanySelect,
		ExpanableLink,
		CollapseTransition,
		MyRangeDatePicker,
		ServicesChart,
	},
	mixins: [PageMixin, PopupMixin, LoginInfoMixin],
	props: {
		showOnfieldOnly : {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			searchMore: false,
			serviceDataList: [],
			companyChartData: null,
			totalCompanyChartData: 0,
			openerChartData: null,
			totalOpenerChartData: 0,
			statusChartData: null,
			totalStatusChartData: 0,
			droneChartData: null,
			totalDroneChartData: 0,
			pagination: {
				pageSize: 10,
				current: 1,
				total: 0,
				hideOnSinglePage: true,
			},
			sort: {
				field: 'serviceUpdatedDatetime',
				order: 'descend',
			},
			filter: {
				droneServiceDroneInstanceId: undefined,
				droneName: null,
				reportByCompanyId: undefined,
				keyword: null,
				statusList: [],
				openDates: {},
				reportBy: null,
				droneModelName: '',
			},
			loading: false,
			activeOnly: true,
		}
	},
	computed: {
		...mapState('user', ['companies']),
		...mapState('auth', ['currentCompany']),
		...mapGetters('user', ['getCompanyById', 'listActiveOnly']),
		statusOptions() {
			const options = Object.values(SERVICE_STATUS)
			return getEnumSelectOptions(this, 'service.status', options)
		},
		getActiveServiceStatusList() {
			return Object.values(SERVICE_STATUS).filter(status => status !== SERVICE_STATUS.STATUS_CANCEL && status !== SERVICE_STATUS.STATUS_CLOSE)
		},
	},
	watch: {
		showOnfieldOnly: {
			handler() {
				this.$router.go()
			},
		},
		filter: {
			handler() {
				this.debounceSearchChange()
			},
			deep: true,
		},
	},
	created() {
		if (this.$notEmpty(this.$route.query.status_list)) {
			const statusList = this.$route.query.status_list.split(',')
			for (const statusCheck of statusList) {
				if (this.statusOptions.findIndex((status) => status.value == statusCheck) >= 0) this.filter.statusList.push(statusCheck)
			}
		} else {
			const defaultOptions = this.getActiveServiceStatusList
			this.filter.statusList = defaultOptions
		}
		this.debounceSearchChange = _debounce(this.handleSearchChange, 300)
	},
	mounted() {
		this.fetchData()
		this.fetchStatusData()
		this.fetchCompanyData()
		this.fetchOpenerData()
		this.fetchDroneData()
	},
	methods: {
		handleSearchChange() {
			// Reset Pagination
			this.pagination.current = 1
			this.fetchData()
		},
		fetchData(toPage = null) {
			if (toPage === null) toPage = this.pagination.current
			const search = {
				pageSize: this.pagination.pageSize,
				currentPage: toPage,
				sortField: this.sort.field,
				sortOrder: this.sort.order,
			}
			this.loading = true
			const filter = this.showOnfieldOnly? {openType: "onfield", ...this.filter } : { ...this.filter }

			axios
				.get('/api/services/search', { params: { search, filter } })
				.then((response) => {
					const data = response.data.data
					const page = data.pagination
					this.serviceDataList = data.services
					this.pagination.current = toPage
					this.pagination.total = page.total
				})
				.catch((error) => {
					this.$message.error(ApiError.getDisplayErrorMessage(this, error))
				})
				.finally(() => {
					this.loading = false
				})
		},
		fetchCompanyData() {
			axios
				.get('/api/services/stat-active-services-by-company')
				.then((response) => {
					this.companyChartData = response.data.data.dataset
					this.totalCompanyChartData = response.data.data.total
				})
				.catch((error) => {
					this.error = ApiError.getDisplayErrorMessage(this, error)
				})
		},
		fetchOpenerData() {
			axios
				.get('/api/services/stat-active-services-by-opener')
				.then((response) => {
					this.openerChartData = response.data.data.dataset
					this.totalOpenerChartData = response.data.data.total
				})
				.catch((error) => {
					this.error = ApiError.getDisplayErrorMessage(this, error)
				})
		},
		fetchStatusData() {
			axios
				.get('/api/services/stat-active-services-by-status')
				.then((response) => {
					this.statusChartData = response.data.data.dataset
					this.totalStatusChartData = response.data.data.total
				})
				.catch((error) => {
					this.error = ApiError.getDisplayErrorMessage(this, error)
				})
		},
		fetchDroneData() {
			axios
				.get('/api/services/stat-active-services-by-drone-model-display-name')
				.then((response) => {
					const data = response.data.data
					const chartData = {}
					for (const dataset of data.dataset) {
						chartData[dataset.name] = dataset.numServices
					}
					this.droneChartData = chartData
					this.totalDroneChartData = data.total
				})
				.catch((error) => {
					this.error = ApiError.getDisplayErrorMessage(this, error)
				})
		},
		handleTableChange(pagination, filters, sorter) {
			this.sort.field = sorter.order === undefined ? null : sorter.columnKey
			this.sort.order = sorter.order
			this.fetchData(pagination.current)
		},
		onClickCompany(name) {
			if (name === undefined) {
				this.filter.reportByCompanyId = undefined
			} else {
				const company = this.companies.filter((c) => {
					if (this.activeOnly && c.company_status != 'active') return false
					return c.name === name
				})
				this.filter.reportByCompanyId = company[0].id
			}
		},
		onClickStatus(status) {
			const options = this.getActiveServiceStatusList
			if (status === undefined) {
				this.filter.statusList = options
			} else {
				const selectedOption = options.find((items) => this.$tenum('service.status', items) === status)
				this.filter.statusList = [selectedOption]
			}
		},
		onClickOpener(name) {
			this.filter.reportBy = name
		},
		onClickDrone(name) {
			this.filter.droneModelName = name
		},
		companySelected() {
			if (this.$refs.companyChart)
				this.$refs.companyChart.resetChart()
		},
		statusSelected() {
			if (this.$refs.statusChart)
				this.$refs.statusChart.resetChart()
		},
	},
}
</script>
<style lang="less" scoped>
.service-status-select {
	min-width: 250px;
	max-width: 550px;
}
.active-services-chart {
	width: 25%;
}
.active-services-chart-panel {
	display: flex;
	flex-direction: 'row';
	justify-content: 'space-between';
	margin-left: 'auto';
	margin-right: 'auto';
}
</style>
