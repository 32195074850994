<i18n src="@i18n/service/service.th.yaml" locale="th" lang="yaml"></i18n>

<template>
	<a-table
		v-bind="$attrs"
		class="mytable mytable-scroll service-table"
		size="small"
		:pagination="pagination"
		v-on="$listeners">
		<a-table-column
			v-if="showOnfieldOnly"
			key="onfield_resume"
			:width="120"
			align="center">
			<template v-slot="{ service }">
				<my-router-link name="onfield/view" :param-value="service.id" :new-window="false">
					<a-icon type="caret-right" />
					{{$t('service.onsite.action.resume')}}
				</my-router-link>
			</template>
		</a-table-column>
		<a-table-column
			:title="$t('common.table.column.no')"
			align="center"
			class="column-row-no">
			<template v-slot="text,record,index">
				{{index + 1 + ((pagination.current - 1) * pagination.pageSize)}}
			</template>
		</a-table-column>
		<a-table-column
			key="serviceNo" :sorter="true"
			:title="$t('service.field.repair_no')"
			data-index="service.serviceNo"
			:width="150">
			<template v-slot="text,record">
				<span v-if="showOnfieldOnly">{{text}}</span>
				<my-router-link v-else :name="serviceLinkName" :param-value="record.id">
					{{text}}
				</my-router-link>
			</template>
		</a-table-column>
		<a-table-column
			key="drone.name" :sorter="false"
			:title="$t('service.field.drone.name_and_sn')"
			:width="190"
			align="center"
			data-index="droneData">
			<template v-slot="droneData">
				<div v-if="showOnfieldOnly && droneData.drone">
					{{droneData.drone.name}}
					<span class="drone-serial-no">({{droneData.drone.serialNo}})</span>
				</div>
				<my-router-link v-else-if="!showOnfieldOnly && droneData.drone" name="drone/view" :param-value="droneData.drone.serialNo || null" :new-window="true" param-name="serial_no">
					{{droneData.drone.name}}
					<span class="drone-serial-no">({{droneData.drone.serialNo}})</span>
				</my-router-link>
			</template>
		</a-table-column>
		<a-table-column
			v-if="showOnfieldOnly"
			key="serviceOnfieldState" :sorter="true"
			:width="150"
			:title="$t('service.field.repair_status')"
			class="column-updated"
			align="center">
			<template v-slot="{ service }" >
				<div>
					{{$tenum('service.onfield.status', service.serviceOnfieldState)}}
				</div>
			</template>
		</a-table-column>
		<a-table-column
			v-else
			key="serviceStatus" :sorter="true"
			:title="$t('service.field.repair_status')"
			:width="150"
			align="center">
			<template v-slot="{ service }">
				<span class="service-table-small">
				<ServiceStatus
					:status="service.serviceStatus"
					:reason="service.serviceStatusReason"/>
				</span>
			</template>
		</a-table-column>
		<a-table-column
			key="serviceCreatedDatetime" :sorter="true"
			:title="$t('service.field.event.date')"
			:width="115"
			align="center"
			data-index="service.serviceCreatedDatetime">
			<template v-slot="text">
				{{$dayjs(text).format("D MMM YY")}} <br />
				<a-icon type="clock-circle" /> {{$dayjs(text).format("H:m")}}
			</template>
		</a-table-column>
		<a-table-column
			key="userCompanyId" :sorter="true"
			:title="$t('service.field.company')"
			data-index="reportBy"
			:width="225"
			align="center">
			<template v-slot="userData">
				<span style="font-size:0.95em">
					{{userData.company.name}}
				</span>
			</template>
		</a-table-column>
		<a-table-column
			key="userUsername" :sorter="true"
			:title="$t('service.field.report_by')"
			data-index="reportBy"
			:width="125"
			align="center">
			<template v-slot="userData">
				<ImageName v-if="showOnfieldOnly" :name="userData.user.username" size="very-tiny" :src="userData.user.image_url">
					<a-icon type="user" />
				</ImageName>
				<my-router-link v-else name="user/view" :param-value="userData.id" new-window>
					<ImageName :name="userData.user.username" size="very-tiny" :src="userData.user.image_url">
						<a-icon type="user" />
					</ImageName>
				</my-router-link>
			</template>
		</a-table-column>
		<a-table-column
			key="serviceUpdatedDatetime" :sorter="true"
			:width="150"
			:title="$t('service.field.updated')"
			align="center">
			<template v-slot="{ service, updatedBy: userData }">
				<div>
					{{$dayjs(service.serviceUpdatedDatetime).format("D MMM YY")}}
					<a-icon type="clock-circle" /> {{$dayjs(service.serviceUpdatedDatetime).format("H:mm")}}
				</div>
				<div>
					{{$t('common.by')}}
					<span v-if="showOnfieldOnly">{{userData.user.username}}</span>
					<my-router-link v-else name="user/view" :param-value="userData.id" new-window>
						{{userData.user.username}}
					</my-router-link>
				</div>
			</template>
		</a-table-column>
	</a-table>
</template>

<script>
import {Table} from "ant-design-vue"
import ServiceStatus from "@components/service/ServiceStatus.vue"
import ImageName from "@components/common/ImageName.vue"

export default {
	components : {
		ImageName,ServiceStatus,
		"a-table" : Table, "a-table-column" : Table.Column
	} ,
	props : {
		pagination : {
			type : null,
			default : () => []
		} ,
		showOnfieldOnly : {
			type : Boolean,
			default : false
		}
	} ,
	computed : {
		serviceLinkName() {
			return 'service/view'
		}
	}
}
</script>

<style lang="less" scoped>
.service-table::v-deep table {
	min-width : 1200px;
	td.service-detail-col {
		min-width : 200px;
		font-size : 0.9em;
		vertical-align: top;
	}
}
.drone-serial-no {
	display : block;
	font-size : 0.9em;
	margin-top : 2px;
}
.service-table-small {
	font-size : 0.9em;
}
</style>
