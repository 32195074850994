<i18n locale="th" lang="yaml" >
	chart.name : "{name}"
</i18n>

<template>
	<ChartContainer
		:loading="false"
		:is-empty="totalData <= 0"
		:error-message="error"
		:custom-legend="true"
		:container-width="300"
		v-bind="$attrs">
			<PieChart ref="pieChart" :chart-dataset="dataset" :options="options" :clickable="true"
				:hide-legend="true" v-on="$listeners" />
		<span slot="title">
			{{$t('chart.name', { name })}} <br/>
		</span>
	</ChartContainer>
</template>


<script>
import ChartContainer from "./general/ChartContainer.vue"
import PieChart from "./general/PieChart.vue"
import _randomColor from "randomcolor"
import {serviceStatusColors} from "@utils/chartUtil"


export default {
	components : {
		ChartContainer ,PieChart
	} ,
	props : {
		chartData: {
			type: Object,
			required: true
		},
		totalData: {
			type: Number,
			required: true
		},
		name : {
			type: String,
			required: true
		},
		isEnumRequired : {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			error : undefined,
		}
	} ,
	computed : {
		dataset() {
			return this.convertDataset(this.chartData, this.isEnumRequired) || {}
		},
		options() {
			return {
				layout: {
					padding: {
						top: 10,
						bottom: 10
					}
				},
				tooltips: {
					callbacks: {
						label: (tooltipItem, data) => {
							let label = ' ' + data.labels[tooltipItem.index]
							if (label)
								label += ': '
							const sum = data.datasets[0].data.reduce((accumulator, curValue) => {
								return accumulator + curValue
							})
							const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
							label += ((value / sum) * 100).toFixed(2) + '%' + ` (${value})`
							return label
						}
					}
				}
			}
		}
	},
	methods : {
		convertDataset(dataset, enumRequired) {
			if (!this.$notEmpty(dataset))
				return null
			const statusColors = serviceStatusColors()
			const counted = [];
			const labels = [];
			const colors = [];
			for(const label in dataset) {
				if (dataset[label] <= 0)
					continue;
					counted.push(dataset[label]);
				if (enumRequired) {
					if (label in statusColors) {
						colors.push(statusColors[label])
					} else {
						colors.push(_randomColor())
					}
					labels.push(this.$tenum('service.status', label))
				} else {
					colors.push(_randomColor())
					labels.push(label)
				}
			}
			return {
				datasets : [{
					data : counted,
					backgroundColor : colors
				}] ,
				labels
			}
		},
		resetChart() {
			this.$refs.pieChart.resetChart()
		}
	}
}
</script>
